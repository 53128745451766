<template>
  <b-card>
    <!-- <b-tabs align="left" class="tab-primary" v-model="tabIndex">
      <b-tab lazy title="Bodegas"> -->
        <warehouse-companies :formTab="form" :openModal="openModal" />
      <!-- </b-tab>
    </b-tabs> -->
    <modal-warehouse-companies :formTab="form" :companyId="this.$route.params.id"/>
  </b-card>
</template>

<script>

import WarehouseCompanies from './warehouses/WarehouseCompanies.vue'
import ModalWarehouseCompanies from './warehouses/ModalWarehouseCompanies.vue'
export default {
  name: 'tabs',
  components: {
    WarehouseCompanies,
    ModalWarehouseCompanies
  },
  data () {
    return {
      form: {},
      tabIndex: 0,
      loading: {
        first: true
      }
    }
  },
  watch: {
    tabIndex () {
      this.form = {}
    }
  },
  methods: {
    openModal (data) {
      if (data) this.form = data
      else this.form = {}
      switch (this.tabIndex) {
      case 0:
        this.$bvModal.show('ModalWarehouseCompanies')
        break
      default:
        break
      }
    }
  }
}
</script>



<!--/*-->
<!--Antiguo warehouse-->
<!--<uib-tab index="2" heading="Bodegas" select="getConfigurationsAll(); getWarehousesList('initial')">-->
<!--<div class="panel-body">-->
<!--  <div class="row">-->
<!--    <div class="col-sm-offset-9 col-sm-3">-->
<!--      <button type="button" ng-click="toggleModal(3)" class="btn btn-orange btn-block"><i-->
<!--          class="fa fa-archive"></i>&nbsp;Nueva bodega</button>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div class="table-responsive">-->
<!--    <table class="table table-striped" ng-if="!loading.loading">-->
<!--      <thead>-->
<!--      <tr>-->
<!--        <th>Código </th>-->
<!--        <th>Nombre </th>-->
<!--        <th>Dirección</th>-->
<!--        <th>Información adicional</th>-->
<!--        @if(Auth::user()->role_id == 1)-->
<!--        <th>Dirección validada</th>-->
<!--        @endif-->
<!--        <th>[[getTranslation(company.country_id, 'place')]]</th>-->
<!--        <th>Nombre contacto</th>-->
<!--        <th>Número contacto</th>-->
<!--        <th>Action</th>-->
<!--      </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--      <tr ng-repeat="warehouse in warehouses">-->
<!--        <td>[[ warehouse.code ]]</td>-->
<!--        <td>[[ warehouse.name ]]</td>-->
<!--        <td>-->
<!--          <span ng-show="warehouse.address.full_address">[[ warehouse.address.full_address ]]</span>-->
<!--          <span ng-show="!warehouse.address.full_address"> &#45;&#45;</span>-->
<!--        </td>-->
<!--        <td>-->
<!--          <span ng-show="warehouse.address.information">[[ warehouse.address.information ]]</span>-->
<!--          <span ng-show="!warehouse.address.information"> &#45;&#45;</span>-->
<!--        </td>-->
<!--        @if(Auth::user()->role_id == 1)-->
<!--        <td>-->
<!--          <i ng-if="warehouse.address.latitude" tooltip-placement="top" uib-tooltip="[[warehouse.address.latitude + ',' + warehouse.address.longitude]]" class="fa fa-check text-success"></i>-->
<!--          <i ng-if="!warehouse.address.latitude" class="fa fa-times text-danger"></i>-->
<!--        </td>-->
<!--        @endif-->
<!--        <td>-->
<!--          <span ng-show="warehouse.address.place.name">[[ warehouse.address.place.name ]]<span ng-if="[2,3,4,5,6].includes(company.country_id)">, [[ warehouse.place_level_1.name ]]</span></span>-->
<!--          <span ng-show="!warehouse.address.place.name"> &#45;&#45;</span>-->
<!--        </td>-->
<!--        <td>-->
<!--          <span>[[ warehouse.contact_name || 'No informado' ]]</span>-->
<!--        </td>-->
<!--        <td>-->
<!--          <span>[[ warehouse.contact_phone || 'No informado' ]]</span>-->
<!--        </td>-->
<!--        <td>-->
<!--          <a href="#" ng-click="toggleModal(4, $index, warehouse)">-->
<!--            Editar-->
<!--          </a>-->
<!--          <a class="text-danger" title="Eliminar bodega" href="#"-->
<!--             ng-click="toggleModal(12,$index,warehouse)">-->
<!--            <i class="fa fa-trash" aria-hidden="true"></i>-->
<!--          </a>-->
<!--        </td>-->
<!--      </tr>-->
<!--      </tbody>-->
<!--    </table>-->
<!--    <div ng-if="loading.loading">-->
<!--      <div class="text-center" ng-show="loading.loading && !loading.isLast">-->
<!--        <h1><i style="color: #666666" class="fa fa-circle-o-notch fa-spin"></i></h1>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div class="text-center" ng-show="true">-->
<!--      <button class="btn btn-default" ng-click="getWarehousesList('back')" ng-if="!loading.loading && paginator.page > 0" ng-disabled="paginator.page === 0">Atrás</button>-->
<!--      <button class="btn btn-default" ng-click="getWarehousesList('forward')" ng-if="!loading.loading && !paginator.areLastWarehouses" ng-disabled="paginator.areLastWarehouses">Adelante</button>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--</uib-tab>-->
<!--*/-->