<template>
  <div>
    <b-row>
      <b-col cols="12 mb-1">
        <b-button variant="warning" class="push-right" @click="() => openModal({})" :disabled="loading.edit || loading.first"><feather-icon icon="PlusIcon"/> Nueva Bodega </b-button>
      </b-col>
      <b-col cols="12" v-show="!loading.first">
        <table-render
          :key="keyTableWhRender"
          id="table_warehouse_companies"
          :schema="schema"
          :rows="rows"
          :actions="actions"
          :loading="loading.warehouse_companies"
          class="px-1"
        >
        </table-render>
        <pagination
          :pagination="pagination"
          :noDigits="false"
          :showSize="true"
          :disabled="loading.warehouse_companies"
        />
      </b-col>
      <div class="table-render-skeleton col-12" v-if="loading.first">
        <b-skeleton-table
          :rows="pagination.limit || 10"
          :columns="schema.length || 10"
          :table-props="{}"
        />
      </div>
    </b-row>
    <modal-warehouse-companies :formTab="warehouse" :companyId="this.$route.params.id" :noGetData="true" ref="modalCreateEditWarehouse"/>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalWarehouseCompanies from './ModalWarehouseCompanies.vue'

export default {
  name: 'WarehouseCompanies',
  components: { ModalWarehouseCompanies },
  data() {
    return {
      schema: [],
      rows: [],
      actions: [],
      loading: {
        first: true,
        edit: true,
        warehouse_companies: false
      },
      keyTableWhRender: 0,
      pagination: {
        limit: 10,
        page: 1,
        total: 0,
        loading: false
      },
      warehouse: {}
    }
  },
  computed: {
    ...mapGetters({
      warehouse_companies: 'getWarehouseCompanies',
      courierCompanies: 'getCourierConfiguration',
      mySession: 'getSession'
    })
  },
  watch: {
    warehouse_companies() {
      if (this.loading.first) {
        const code = this.warehouse_companies.country.code.toLowerCase()
        const { Place_id } = this.$i18nImport(`${code}.es`)[`${code}.es`]
        this.schema[this.m_getElementIndex(this.schema, 'key', 'comuna')].label = Place_id
      }
      this.rows = this.warehouse_companies.rows.map(warehouse => ({
        ...warehouse,
        validate: `<i class='fa fa-${warehouse.address.latitude ? 'check-circle text-success' : 'times-circle text-danger'}'></i>`,
        information: warehouse.address.information,
        comuna: warehouse.address.place.name,
        full_address: warehouse.address.full_address
      }))
      this.pagination.total = this.warehouse_companies.pagination.total
      this['pagination.limit'] = this.warehouse_companies.pagination.pages
      if (this.loading.first) this.$refs.modalCreateEditWarehouse.setCourierCompany()
      this.loading.first = false
    },
    'pagination.page'() {
      this.getWarehouses()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.getWarehouses()
    },    
    mySession() {
      if (this.mySession.id) this.addColumn()
    },
    courierCompanies() {
      this.loading.edit = false
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.schema = [
        { key: 'code', label: 'Código' },
        { key: 'name', label: 'Nombre', sortable: true },
        { key: 'full_address', label: 'Dirección' },
        { key: 'information', label: 'Información adicional', doubleDash: true },
        { key: 'comuna', label: 'Comuna', doubleDash: true },
        { key: 'contact_name', label: 'Nombre contacto', doubleDash: true },
        { key: 'contact_phone', label: 'Número contacto' },
        {key: 'actions', label: 'Acciones', class: ['text-center']}
      ]
      this.addColumn()

      this.actions = [
        { name: 'Editar', action: id => this.openModalUpdate(id), key: 'edit', icon: 'EditIcon', color: 'primary' },
        { name: 'Eliminar', action: id => this.confirmDeactivateWarehouse(id), key: 'delete', icon: 'TrashIcon', color: 'danger' }
      ]
      this.getInitialData()
    },
    addColumn() {
      if (['admin', 'superadmin'].includes(this.$session.get('cas_user')?.role)) {
        this.schema.splice(
          this.schema.findIndex((el) => el.key === 'information') + 1,
          0,          
          { key: 'validate', label: 'Direción validada',  class: ['text-center'], useDangerHTML: true }
        )
      }
    },
    getInitialData() {
      this.loading.first = true
      this.loading.edit = true
      const queryParams = {}
      const params = {
        company_id: parseInt(this.$route.params.id)
      }

      this.$store.dispatch('fetchService', { name: 'getCourierConfiguration', queryParams, params, hideAlert: true, onError: this.errorCarrierConfigurationData })
      this.$store.dispatch('fetchService', { name: 'getCountriesQuoter' })
      this.getWarehouses()
    },
    getWarehouses() {
      const params = {
        company_id: parseInt(this.$route.params.id)
      }
      const queryParams = {
        'page': this.pagination.page,
        'limit': this.pagination.limit
      }
      this.$store.dispatch('fetchService', { name: 'getWarehouseCompanies', queryParams, params })
    },
    errorCarrierConfigurationData() {
      this.loading.edit = false
    },
    openModalUpdate(_id) {
      const data = {
        ...this.warehouse_companies.rows.find(warehouse => warehouse.id === _id),
        additional: this.warehouse_companies.rows.find(warehouse => warehouse.id === _id).address.information
      }
      data.place = { ...data.address.place, text : data.address.place.name }
      this.openModal(data)
    },
    confirmDeactivateWarehouse(_id) {
      this.$yesno(this.$t('msg-pregunta-eliminar-seleccionados'), () => this.deactivateWarehouse(_id))
    },
    deactivateWarehouse(id) {
      const params = {
        company_id: parseInt(this.$route.params.id),
        warehouse_id: id
      }
      this.$store.dispatch('fetchService', { name: 'deleteWarehouseCompanies', params, onSuccess: this.deleteComplete })
    },
    deleteComplete() {
      this.$success(this.$t('msg-exito-eliminar-elemento'))
      this.getInitialData()
    },
    openModal (form) {
      this.warehouse = form
      this.$bvModal.show('ModalWarehouseCompanies')
    }

  }

}
</script>

<style>
</style>
